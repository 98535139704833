


// General Colors
:root {
--primary_color: #0F3022;
--secondary_color: #F4F4F4;
--text_color:#122714;

--golf_dark: #151614;
--golf_rough: #14241D;
--golf_fairway: #3FAE86;
--golf_green: #429254;

--green: #43CC9E;
--btn_color: #CCEADD;
--btn_border: #2A4A2C;
--btn-bg: #41AF87;

--placeholder-color: rgba(black , .35);
--inputBorder:#B9B9B9;
--transition_style: all 0.3s ease-in-out;

// Fonts
--font-primary: "Hanken Grotesk", sans-serif;
--font-secondary: 'Blood Orange';

}

@font-face {
  font-family: 'Blood Orange';
  src: url('../fonts/bloodorange-webfont.woff2') format('woff2'),
         url('../fonts/bloodorange-webfont.woff') format('woff');      
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


// mixins ------------

@mixin scrollbar-style( $scrollTrackbg:#DFDFDF,$scrollThumbBg:var(--primary_color)) {
	* {scrollbar-width: thin;}
  scrollbar-color: $scrollThumbBg $scrollTrackbg;
  // *::-webkit-scrollbar {width: 0.375rem;height: 0.625rem;}
  // *::-webkit-scrollbar-track {background: $scrollTrackbg;}
  // *::-webkit-scrollbar-thumb {background-color: $scrollThumbBg;border-radius: 2rem;}
}


// Placeholder prefix list
$placeholder-prefix-list: ':-webkit-input', ':-moz', '-moz', '-ms-input';
@mixin placeholder( $color:var(--placeholder-color), $opacity: .5,$txtCenter:left,$fontWeight:400) {
    @each $prefix in $placeholder-prefix-list {
        &:#{$prefix}-placeholder {
            color: $color;font-size:1.125rem;font-family:var(--font-primary);opacity:$opacity;transition: var(--transition_style);text-align:$txtCenter;font-weight:$fontWeight;
        }
        // Set focus color
        &:focus:#{$prefix}-placeholder {
            color: rgba( $color, .2);
        }
    }
}

@for $i from 1 through 6{
  h#{$i}{margin: 0;line-height: normal;font-family: var(--font-primary);color: var(--primary_color);position: relative;font-weight: 600;}
}

// @mixin flexprop($display:flex,$alignItems:center,$justifyContet:center){
//   display:$display;align-items: $alignItems;justify-content: $justifyContet;
// }