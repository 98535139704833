@import "../node_modules/bootstrap/scss/bootstrap";
@import "../public/assets/styles/variables.scss";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

body,
html {
  width: 100%;
  // height: 100%; commenting for toaster fixing
  font-family: var(--font-primary);
  color: var(--text_color);
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: #F0EFEE;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
}

a {
  text-decoration: none;
  outline: none !important;
  transition: var(--transition_style);
}

a img {
  border: none;
}

a,
a:hover,
a:active,
a:focus,
button,
.btn,
.close {
  outline: none !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
}

::selection {
  background-color: var(--primary_color);
  color: white;
}

.btn:focus,
.btn:active {
  box-shadow: none !important;
}

button, button * {
  user-select: none;
}

p {
  margin: 0;
}

:root {
  @include scrollbar-style;
  scrollbar-width: thin;
}

h1 {
  font-size: 5.625rem;
  font-family: var(--font-secondary);
  color: var(--secondary_color);
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.25rem;
}

@media(max-width:1200px) {
  h1 {
    font-size: 3.75rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 1.875rem;
  }
}

@media(max-width:991px) {
  h1 {
    font-size: 3.125rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.875rem;
  }
}

@media(max-width:767px) {
  h1 {
    font-size: 2.75rem;
  }

  h4 {
    font-size: 1.75rem;
  }

  p {
    font-size: 0.875rem;
  }
}

.common-btn {
  background: var(--btn-bg);
  border-radius: 2.5rem;
  color: white;
  font-size: 1.375rem;
  padding: 0.20rem 1.25rem;
  transition: var(--transition_style);
  border: none;
  position: relative;
  cursor: pointer;
  font-family: var(--font-primary);
  min-height: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: 0 10px 10px rgba(0, 0, 0, .16);
  }

  &:active {
    background: var(--btn_color) !important;
  }

  &.sclBtn {
    background: white;

    img {
      position: absolute;
      left: 1rem;
    }
  }

  @media(max-width:991px) {
    font-size: 1.25rem;
    min-height: 50px;
  }
}
.btn-shadow-hover {
  transition: all 0.3s ease;
  &:hover,
  &:focus {
    box-shadow: 0 10px 10px rgba(0, 0, 0, .16) !important;
  }
}

.form-control {
  background-color: white;
  color: var(--text_color);
  font-size: 1.25rem;
  box-shadow: none;
  width: 100%;
  min-height: 55px;
  transition: var(--transition_style);
  border-radius: 16px;
  padding: 0.25rem 1.25rem;
  text-align: left;
  text-overflow: ellipsis;
  border: none;
  @include placeholder(rgba(#041232, .5), 1, left, 400);

  &:focus {
    background-color: white;
    color: var(--primary_color);
    border-color: none;
  }

  @media(max-width:767px) {
    font-size: 1.125rem;
  }
}

textarea.form-control {
  height: 300px;
  resize: none;
}

.tickBg {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  background: #cceadd;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary_color);
  padding: 0.25rem;
  position: absolute;
  right: -0.625rem;
  top: -0.625rem;
  opacity: 0;
  transition: var(--transition_style);
}

.blurElement {
  filter: blur(5px);
  overflow: hidden;
}

.blackBg {
  background: #1e1e1e;
  width: 100%;
  height: 100%;
  border-top: 1.625rem solid var(--primary_color);
  // padding: 1.25rem;
  // overflow: auto;
  position: fixed;

  @media (max-width: 767px) {
    border-top: 0.625rem solid var(--green);
    flex-direction: column;
    // position: relative;
    // height: auto;
  }

  &.bgGray {
    background: var(--secondary_color);
  }
}

// dark theme

body.dark-theme {
  .blackBg {
    background: #1e1e1e !important;
  }
}

.cont_area {
  overflow: auto;
  position: relative;
  z-index: 1;
}

.btmGolf_img {
  position: fixed;
  // left: 0;
  left: 85px;
  right: 0;
  bottom: -1.25rem;
  margin: auto;
  pointer-events: none;
  z-index: 0;

  &.bigGolf {
    bottom: -25%;
  }

  @media (max-width: 767px) {
    left: 0;

    &.bigGolf {
      bottom: -20%;
    }
  }

  @media (max-width: 576px) {
    &.bigGolf {
      bottom: -0.5rem;
    }
  }
}

// .splash-wrap {
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   background: #c8dfd5;
//   z-index: 99;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .splash-inner {
//     width: 200px;
//     height: 200px;
//     position: relative;
//     img {
//       position: absolute;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       margin: auto;
//       top: 0;
//       // &:first-child {
//       //   z-index: 9;
//       //   max-width: 140px;
//       // }
//       // &:last-child {
//       //   animation: rotate 1.3s linear infinite;
//       // }
//     }
//   }
// }

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}



/**
 * ============================================================================================
 * Common elements
 * ============================================================================================
 */

.caddyBoxBg {
  max-width: 460px;
  width: 100%;
  margin: 1.25rem auto;

  [class*="col-"] {
    padding: 0.5rem;
  }

  .box {
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    border: 2px solid var(--green);
    border-radius: 0.813rem;
    padding: .75rem;
    background: white;
    transition: var(--transition_style);
    cursor: pointer;
    position: relative;

    &:hover {
      box-shadow: 0 10px 10px rgba(0, 0, 0, .26);
      border-color: white;
    }

    &.slected {
      .tickBg {
        opacity: 1;
      }
    }

    .imgBox {
      width: 4.375rem;
      height: 4.375rem;
      border-radius: 50%;
      border: 2px solid var(--green);
      overflow: hidden;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        height: 4.35rem;
        object-fit: cover;
      }
    }

    &__cont {
      padding-left: 0.625rem;

      h6 {
        font-size: 1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 1.25rem;
        color: var(--text_color);
        text-align: left;
      }
    }
  }
}


// Modal ==================================================
.modal-open {
  .blackBg.bgGray {
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }
}

.modal-content {
  border-radius: 1.125rem;
  border: none;
  text-align: center;
  background: #fff;
  * {
    color: var(--text_color);
    line-height: normal;
  }

  .modal-body {
    padding: 3.125rem 1.25rem 1.5rem;
  }

  .modalContArea {
    max-width: 525px;
    margin: auto;

    p {
      font-size: 1.5rem;

      b {
        text-decoration: underline;
      }

      &.sm_txt {
        font-size: 1.25rem;
      }

      @media(max-width:1200px) {
        font-size: 1.25rem;

        &.sm_txt {
          font-size: 1rem;
        }
      }
    }

    .txtAreaBg {
      margin: 1.25rem 0;
      position: relative;
      border: 2px solid var(--text_color);
      border-radius: 2.5rem;
      box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .25);
      min-height: 200px;
      overflow: hidden;

      .form-control {
        text-align: left;
        height: auto;
        min-height: auto;
        padding: 0.875rem 1.25rem 0.875rem 1.25rem;
        border-radius: .25rem;
        border: none;
        font-size: .875rem;
      }

      .btnArea {
        position: absolute;
        right: .5rem;
        bottom: .5rem;
        display: flex;
        z-index: 1;

        span {
          width: 2.75rem;
          height: 2.75rem;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: 2px solid var(--btn_border);
          margin-right: .5rem;
          transition: var(--transition_style);
          cursor: pointer;

          &:hover {
            background: var(--green);
          }
        }
      }
    }

    .btn {
      width: 100%;
      max-width: 325px;
      background: #BFBEBE;
      font-size: 1.25rem;

      &.grn {
        background: var(--btn_color);
      }

      &:hover {
        border-color: var(--btn_border);
      }
    }
  }
}

// Modal ==================================================


// loadmore ==================================================
.loadmore {
  margin: 1rem auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  min-width: 240px;
  height: 3.5rem;
  padding: .5rem;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .16);
  border-radius: 1.875rem;
  font-size: 1.25rem;
  cursor: pointer;

  .dotBg {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    border: 2px solid var(--btn_border);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  span {
    margin-left: 1rem;
    color: #122714;
  }
}

// loadmore ==================================================
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: .25rem;
  height: .25rem;
  border-radius: 0.313rem;
  background-color: var(--primary_color);
  color: var(--primary_color);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 0.313rem;
  background-color: var(--primary_color);
  color: var(--primary_color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

.dot-flashing::before {
  left: -0.5rem;
}

.dot-flashing::after {
  left: 0.5rem;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--primary_color);
  }

  50%,
  100% {
    background-color: rgba(15, 48, 34, .2);
  }
}

/**
 * ==============================================
 * pulse
 * ==============================================
 */
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse1 {
  animation: animate-pulse 3s linear infinite;
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7), 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  40% {
    box-shadow: 0 0 0 50px rgba(0, 0, 0, 0.0), 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  80% {
    box-shadow: 0 0 0 50px rgba(0, 0, 0, 0.0), 0 0 0 30px rgba(0, 0, 74, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0), 0 0 0 30px rgba(0, 0, 74, 0);
  }

}


@media (max-width: 767px) {
  .d-mobile-none {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .d-desktop-none {
    display: none !important;
  }
}

.modal-backdrop {
  --bs-backdrop-zindex: -1 !important;
}


// Toaster Style ==================================================

.toast-container {
  .ngx-toastr {
    padding: 0;
    border-radius: 1rem;
    background: rgba(193, 193, 193, 0.7);
    padding: .5rem;
    box-shadow: 0 0 12px rgba(0, 0, 0, .16);

    &:hover {
      box-shadow: 0 0 12px rgba(0, 0, 0, .16);
    }
  }

  .ribbon-wrapper {
    background: transparent;
    border: none;

    .card-body {
      border-radius: 1rem;
      border: 3px solid transparent;
    }

    &.success .card-body {
      * {
        color: #0f5132;
      }

      background-color: #d1e7dd;
      border-color: #0f5132;
    }

    &.danger .card-body {
      * {
        color: #842029;
      }

      background-color: #f8d7da;
      border-color: #842029;
    }

    &.warning .card-body {
      * {
        color: #664d03;
      }

      background-color: #fff3cd;
      border-color: #664d03;
    }

    &.info .card-body {
      * {
        color: #055160;
      }

      background-color: #cff4fc;
      border-color: #055160;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      font-weight: 700;
      font-size: 1.125rem;
    }

  }
}

.btn-img {
  max-height: 32px;
}


// voice record wave animation
.bars {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .bar {
    background: #122714;
    bottom: 1px;
    height: 3px;
    width: 3px;
    margin: 0px 4px;
    border-radius: 5px;
    animation: sound 0ms -600ms linear infinite alternate;
  }
}

@keyframes sound {
  0% {
    opacity: .5;
    height: 4px;
  }

  100% {
    opacity: 1;
    height: 30px;
  }
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 474ms;
}

.bar:nth-child(2) {
  left: 15px;
  animation-duration: 433ms;
}

.bar:nth-child(3) {
  left: 29px;
  animation-duration: 407ms;
}

.bar:nth-child(4) {
  left: 43px;
  animation-duration: 458ms;
}

.bar:nth-child(5) {
  left: 57px;
  animation-duration: 400ms;
}

.bar:nth-child(6) {
  left: 71px;
  animation-duration: 427ms;
}

.bar:nth-child(7) {
  left: 85px;
  animation-duration: 441ms;
}

.bar:nth-child(8) {
  left: 99px;
  animation-duration: 419ms;
}

.bar:nth-child(9) {
  left: 113px;
  animation-duration: 487ms;
}

.bar:nth-child(10) {
  left: 127px;
  animation-duration: 442ms;
}

.loadingItem i {
  position: relative;
  overflow: hidden;
  display: inline-block;
  user-select: none !important;

  img {
    opacity: 0;
  }

  &:after {
    position: absolute;
    width: 100%;
    background: #ccc;
    content: '';
    display: inline-block;
    left: 0;
    top: 0;
    bottom: 0;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    border-radius: 3px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.dskMenuBg.open~.cont_area .answer_cont .inputBg {
  max-width: 48%;
}

.dark-theme {
  .btmGolf_img.bigGolf {
    opacity: .3;
  }
}




// latest style ======================================================================================================================================================

.smlBtn{
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition_style);
  border-radius: 24px;
  padding: 2px 18px;
  background: white;
  border: 1px solid white;
  color: var(--golf_rough);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  line-height: 27px;
  &.dark{
    background: var(--golf_dark);
    color: white;
    border-color: var(--golf_rough);
  }
  &.black{
    background: black;
    color: white;
    border-color: var(--golf_rough);
  }
  & + .smlBtn{
    margin-left: 10px;
  }
  &:hover{
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  }
}

.imgBlock{
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  transition: var(--transition_style);
  >img{
    border-radius: 12px;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    transition: var(--transition_style);
  }
}

@media (min-width: 992px){
  .cameraModal{
    --bs-modal-width: 770px;
  }
}

.cameraModal{
  .modal-content{
    border-radius: 20px;
    padding: 20px;
    background: #e8e8e8;
  }
  .modal-body{
    border-radius: 20px;
    background: white;
    padding: 0;
    height: 340px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-footer{
    justify-content: center;
    align-items: center;
    height: 95px;
    a{
      width: 37px;
      height: 37px;
      border-radius: 50%;
      background: #182F23;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      opacity: .5;
      position: relative;
      img{
        position: absolute;
        transition: var(--transition_style);
        max-width: 22px;
        &.white{
          opacity: 0;
        }
      }
      + a{
        margin-left: 16px;
      }
      &:hover{
        opacity: 1;
        img{
          &.white{
            opacity: 1;
          }
          &.black{
            opacity: 0;
          }
        }
      }
    }
    .clickBg{
      width: 115px;
      text-align: center;
    }
    .clickBtn{
      width: 3.438rem;
      height: 3.438rem;
      border-radius: 50%;
      background: #F8F8F8;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: var(--transition_style);
      cursor: pointer;
      &:before{
        content: '';
        position: absolute;
        width: 4.375rem;
        height: 4.375rem;
        background: transparent;
        border: 2px solid #F8F8F8;
        border-radius: 50%;
        pointer-events: none;
      }
      &:active{
        width: 3.125rem;
        height: 3.125rem;
      }
    }
    .close{
      max-width: 18px;
      cursor: pointer;
    }
  }
}

// chat_and_fileUpload ==================================
.chat_and_fileUpload{
  background: #E8E8E8;
  border-radius: 24px;
  padding: 16px;
  position: relative;
  transition: all ease-in-out .4s;
  .addBtn{
    width: 55px;
    height: 48px;
    background: white;
    border-radius: 16px;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid #EFEDED;
  }
  .position-relative{
    width: 100%;
    // padding-left: 8px;
    img{
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: .4;
    }
  }
  input{
    height: 48px;
    border: 2px solid #EFEDED;
    border-radius: 16px;
    width: 100%;
    padding: 2px 40px 2px 15px;
    text-overflow: ellipsis;
  }
  @media(max-width:767px){
    padding: 10px;
  }
}
.fileUploadBox{
  background: #E8E8E8;
  border-radius: 24px 24px 0 0;
  padding: 16px;
  transition: var(--transition_style);
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  transform: scale(.8);
  top: -50px;
  z-index: 1;
  @media(max-width:767px){
    transform: scale(.6);
  }
  .btns{
    width: 100%;
    height: 37px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    margin-bottom: 8px;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.10);
    color: var(--golf_fairway);
    font-size: 16px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    input{
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    &.green{
      background: var(--golf_green);
      color: white;
    }
    &.black{
      background: var(--golf_rough);
      color: white;
    }
    &:last-child{
      margin: 0;
    }
  }
}
.chat_and_fileUploadBg{
  position: relative;
  &.active{
    .chat_and_fileUpload{
      border-radius: 18px 18px 24px 24px;
    }
    .fileUploadBox{
      top: -145px;
      opacity: 1;
      transform: scale(1);
    }
  }
}
// chat_and_fileUpload ==================================

.mainBG{
  display: flex;
  @media(max-width:767px){
    padding-bottom: 80px;
  }
}
@media (max-width: 1400px) {
  .container{
    max-width: calc(100% - 160px);
  }
}
@media (max-width: 767px) {
  .container{
    max-width: calc(100% - 16px);
    padding: 0 8px;
  }
}

// chatArea style ==================================
.chatArea{
  padding-top: 40px;
  border-top: 1px solid rgba(0,0,0,.10);
  *{
    color: black;
  }
  h5{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 10px;
  }
}
.chatBox{
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .chat{
    background: white;
    border: 1px solid rgba(20, 36, 29, .1);
    border-radius: 24px;
    padding: 12px 22px;
    position: relative;
    display: inline-block;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: normal;
    &:last-child{
      margin-bottom: 0;
    }
    span{
      position: absolute;
      top: -9px;
      left: 22px;
      display: inline-flex;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      color: var(--golf_dark);
      padding: 2px 8px;
      background: #C4F1DD;
      border-radius: 40px;
    }
  }
}
// chatArea style ==================================

.experienceGolf-list,.rule-list{
  .drag-scroll-wrapper{
    overflow: unset!important;
  }
  .drag-scroll-content{
    // height: 100%!important;
    padding-top: 5px;
    display: flex !important;
    align-items: flex-start;
    // max-width: calc(100% - 120px);
    max-width: 100%;
  }
}
.file_preview{
  display: flex;
  align-items: flex-start;
  margin-bottom:-14px;
  background: #E8E8E8;
  border-radius: 24px 24px 0 0;
  padding: 16px;
  transition: var(--transition_style);
  .file_preview__items{
    border-radius: 25px;
    overflow: hidden;
    border: 2px solid var(--primary_color);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .16);
    position: relative;
    max-width: 130px;
    margin: 4px;
    max-height: 130px;
    .remove{
      width: 21px;
      min-width: 21px;
      height: 21px;
      border-radius: 50%;
      background: #43CC9E;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--primary_color);
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 0;
      img{
        max-width: 12px;
      }
    }
  }
}

.chat__cont{
  .drag-scroll-content{
    align-items: flex-end;
  }
}

// fix toaster position

.toast-container{
  position: fixed !important;
}

.menuBlock{
  display: inline-flex;
  justify-content: center;
  background: white;
  padding: 8px;
  border-radius: 12px;
  @media(max-width: 991px){
    li, li a{
      width: 100% !important;
    }
  }
  li{
    margin-right: 6px;
    &:last-child{
      margin: 0;
    }
    *{
      font-weight: 500;
    }
    a{
      font-size: 12px;
      color: #404040;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 12px 9px;
      border-radius: 8px;
      background: #F0EFEE;
      width: 120px;
      height: 46px;
      span{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 22px;
        padding: 2px;
        border-radius: 2px;
        border: 1px solid #404040;
        text-transform: uppercase;
        margin-right: 5px;
        transition: var(--transition_style);
        color: #404040;
      }
      &:hover,&.active{
        color: white !important;
      }
      &.default{
        .white{
          opacity: 0;
        }
        &:hover,&.active{
          background: var(--golf_fairway);
          .gray{
            opacity: 0;
          }
          .white{
            opacity: 1;
          }
        }
      }
      &.pga{
        &:hover,&.active{
          background: #003C80;
          span{
            background: white;
            color: #003C80;
          }
        }
      }
      &.liv{
        &:hover,&.active{
          background: black;
          span{
            background-color: #BDFF69;
            color: black;
          }
        }
      }
      &.owgr{
        &:hover,&.active{
          background: #BD8100;
        span{
          background: black;
          color: white;
        }
        }
      }
      @media(max-width:767px){
        width: 100%;
        height: 100%;
        flex-direction: column;
        text-align: center;
        span{
          margin: 0 0 4px;
        }
        .position-relative{
          margin: 0 0 4px;
          &.homeIcons{
            width: 23px;
            height: 24px;
            img{
              max-width: 23px;
            }
          }
        }
      }
      @media(max-width:576px){
        font-size: 10px;
        line-height: normal;
        padding: 10px 5px;
      }
    }
    .position-relative{
      margin-right: 5px;
      &.homeIcons{
        width: 14px;
        height: 14px;
        img{
          max-width: 14px;
        }
      }
      &.playIcons{
        width: 12px;
        height: 20px;
      }
      &.articleIcons{
        width: 28px;
        height: 20px;
        img{
          max-width: 28px;
        }
      }
      img{
        position: absolute;
        left: 0;
        transition: var(--transition_style);
      }
    }
  }
  @media(max-width:767px){
    width: 100%;
    li{
      width: 100%;
    }
  }
}

